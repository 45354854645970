import { ASIN_AMAZON_LINK_BASE } from "../config/routes.config"

export class UtilHelper {
    /**
     * Return value or empty
     *
     * @param item
     */
    static toString(item: any): string {
        return item || ""
    }

    /**
     * Check if variable is object
     *
     * @param item
     */
    static isObject(item: any): boolean {
        return typeof item === "object" && item !== null
    }

    /**
     * Check if object is empty
     *
     * @param item
     */
    static isEmptyObject(item: any): boolean {
        return UtilHelper.isObject(item) && Object.keys(item).length === 0 && item.constructor === Object
    }
    static truncateTitle = (title: string, maxLength: number) => {
        if (title.length <= maxLength) {
            return title
        } else {
            return title.slice(0, maxLength) + "..."
        }
    }

    /**
     * Check if objects are equal
     *
     * @param obj1
     * @param obj2
     */
    static objectsEqual(obj1: Object, obj2: Object): boolean {
        return JSON.stringify(obj1) === JSON.stringify(obj2)
    }

    /**
     * Deep copy object
     *
     * @param obj
     */
    static deepCopyObject(obj: Object): any {
        return JSON.parse(JSON.stringify(obj))
    }

    /**
     * Stringify inner objects of some object
     */
    static stringifyInnerObjects(item: IIndexable): IIndexable {
        if (!UtilHelper.isObject(item)) {
            return item
        }

        const result = {} as IIndexable

        Object.keys(item).forEach((key) => {
            result[key] = UtilHelper.isObject(item[key]) ? JSON.stringify(item[key]) : item[key]
        })

        return result
    }

    /**
     * Convert to mysql date format: YYYY-MM-DD
     *
     * @param date
     */
    static dateToMysqlFormat(date: Date): string {
        const year = String(date.getFullYear())

        let month = String(date.getMonth() + 1)

        if (month.length === 1) {
            month = "0" + month
        }

        let day = String(date.getDate())

        if (day.length === 1) {
            day = "0" + day
        }

        return year + "-" + month + "-" + day
    }

    /**
     * Format cents to dollars
     *
     * @param amount
     */
    static formatCents(amount: number): number {
        return parseFloat((amount / 100).toFixed(2))
    }

    /**
     * Round
     *
     * @param amount
     */
    static round(amount: number): number {
        return Number(amount.toFixed(2))
    }
    /**
     * Format number in locale fashion, with comma delimiters for thousands for example
     *
     * @param num
     */
    static getFormattedLocaleNumber(num: number): string {
        return num.toLocaleString("en-US")
    }

    /**
     * Scroll to top of the page
     */
    static scrollTopPage() {
        // Hack to scroll to top of page
        // Needed because we can navigate to these pages with window scrolled - so it's glitchy without it
        // In catch so it doesn't fail where not supported...

        try {
            window.scrollTo(0, 0)
        } catch (e) {}
    }

    /**
     * Get url query string param
     *
     * @param name
     * @param url
     */
    static getUrlQueryParam(name: string, url: string = ""): string | undefined {
        try {
            url = url || window.location.href
            name = name.replace(/[[\]]/g, "\\$&")

            const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)")
            const results = regex.exec(url)

            if (!results) {
                return undefined
            }

            if (!results[2]) {
                return ""
            }

            return decodeURIComponent(results[2])
        } catch (e) {
            return undefined
        }
    }

    /**
     * Convert file to base 64 format
     *
     * @param file
     */
    static async fileToBase64(file: File) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()

            reader.readAsDataURL(file)

            reader.onload = () => resolve(reader.result)
            reader.onerror = (error) => reject(error)
        })
    }

    /**
     * Capitalize first letter in string
     *
     * @param item
     */
    static capitalizeFirstLetter(item?: string): string {
        if (!item) {
            return ""
        }

        return item.charAt(0).toUpperCase() + item.slice(1)
    }

    /**
     * Get current url of app
     */
    static getCurrentAppDomain(): string {
        return (
            window.location.protocol +
            "//" +
            window.location.hostname +
            (window.location.port ? ":" + window.location.port : "")
        )
    }

    /**
     * convert to valid code
     *
     * @param code
     */
    static getValidCode(code: string) {
        const number = Number(code)
        if (isNaN(number)) {
            return null
        }
        return number
    }

    /**
     * Get periodic change in percentages for 2 values
     *
     * @param currentValue
     * @param previousPeriodValue
     */
    static getPeriodicChangePercentage(currentValue?: number, previousPeriodValue?: number): number {
        if (typeof currentValue === "undefined" || typeof previousPeriodValue === "undefined") {
            return 0
        }

        try {
            const result = (100 * (currentValue - previousPeriodValue)) / ((currentValue + previousPeriodValue) / 2)

            if (isNaN(result)) {
                return 0
            }

            return result
        } catch (e) {
            return 0
        }
    }

    /**
     * Check if current Device is IOS
     */
    static isIOS() {
        return (
            ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(
                navigator.platform
            ) ||
            // iPad on iOS 13 detection
            (navigator.userAgent.includes("Mac") && "ontouchend" in document)
        )
    }

    /**
     * Basically format seconds to "hh:mm:ss"
     */
    static getFormattedCountdown(secondsToFormat?: number): string | undefined {
        if (!secondsToFormat) {
            return undefined
        }

        const hours = Math.floor(secondsToFormat / 60 / 60)
        const minutes = Math.floor(secondsToFormat / 60) - hours * 60
        const seconds = secondsToFormat - hours * 60 * 60 - minutes * 60

        return (
            `${hours ? `${hours}h ` : ""}` +
            `${minutes ? `${minutes}m ` : "0m "}` +
            `${seconds ? `${seconds}s ` : "0s"}`
        )
    }

    /**
     * Give delay using Promise in asych call
     *
     * @param mil
     */
    static async sleep(num: number): Promise<null> {
        return new Promise((resolve) => {
            setTimeout(resolve, num)
        })
    }
    static areIdsSame(arr: IActualAsin[]) {
        if (!arr) {
            return false
        }
        if (arr.length === 0) {
            return false // If array is empty, return false
        }

        const firstId = arr[0].id
        for (let i = 1; i < arr.length; i++) {
            if (arr[i].id !== firstId) {
                return true // If any id doesn't match the first id, return false
            }
        }
        return false // If all ids match, return true
    }

    static removeSpacesFromStringArray = (arr: string[]): string[] => {
        for (let i = 0; i < arr.length; i++) {
            arr[i] = arr[i].replace(/\s/g, "") // Remove all spaces
        }
        return arr
    }
    static getInitials(username: string) {
        return username
            .split(" ") // Split the username into words
            .map((word) => word.charAt(0)) // Get the first character of each word
            .join("") // Join the characters to form the initials
            .toUpperCase() // Convert to uppercase
    }
    static createDefaultObject<T extends keyof IOnBoardUser>(keys: T[]): DefaultOnBoardUser {
        const defaultObject: DefaultOnBoardUser = {}
        keys.forEach((key) => {
            defaultObject[key as string] = undefined
        })
        return defaultObject
    }
    /**
     * Group array of objects by given keys
     * @param keys keys to be grouped by
     * @param array objects to be grouped
     * @returns an object with objects in `array` grouped by `keys`
     * @see <https://gist.github.com/mikaello/06a76bca33e5d79cdd80c162d7774e9c>
     */
    static groupBy =
        <T>(keys: (keyof T)[]) =>
        (array: T[]): Record<string, T[]> =>
            array.reduce(
                (objectsByKeyValue, obj) => {
                    const value = keys.map((key) => obj[key]).join("-")
                    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj)
                    return objectsByKeyValue
                },
                {} as Record<string, T[]>
            )
    static getCurrencySymbol(currency: string) {
        const currency_symbols: Record<string, string> = {
            USD: "$", // US Dollar
            EUR: "€", // Euro
            GBP: "£", // British Pound Sterling
            JPY: "¥", // Japanese Yen
            CNY: "¥", // Chinese Yuan Renminbi
            AUD: "A$", // Australian Dollar
            CAD: "$", // Canadian Dollar
            BRL: "R$", // Brazilian Real
            MXN: "$", // Mexican Peso
            INR: "₹", // Indian Rupee
            SGD: "$", // Singapore Dollar
            CRC: "₡", // Costa Rican Colón
            ILS: "₪", // Israeli New Sheqel
            KRW: "₩", // South Korean Won
            NGN: "₦", // Nigerian Naira
            PHP: "₱", // Philippine Peso
            PLN: "zł", // Polish Zloty
            PYG: "₲", // Paraguayan Guarani
            THB: "฿", // Thai Baht
            UAH: "₴", // Ukrainian Hryvnia
            VND: "₫", // Vietnamese Dong
        }
        return currency_symbols[currency] || (currency as string)
    }

    static async getJsonResponse(response: Response): Promise<any> {
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`)
        }

        try {
            const jsonData = await response.json()
            return jsonData
        } catch (error) {
            console.error("Failed to parse JSON:", error)
            throw error
        }
    }
}

export const isTokenExpired = (token: string | undefined) => {
    if (!token) return true

    const expiry = JSON.parse(atob(token.split(".")[1])).exp
    return expiry * 1000 < Date.now()
}

export const calculateDays = (datetimeFrom: string) => {
    const dateFrom = new Date(datetimeFrom)
    const dateUntil = new Date()
    // @ts-ignore
    const timeDifference = dateUntil - dateFrom

    const daysDifference = timeDifference / (1000 * 60 * 60 * 24)

    return Math.floor(daysDifference)
}

export const isMatchingAttributeFormat = (attribute: string) => {
    return !!attribute && /^[A-Z_]+$/.test(attribute)
}

export const getJwtToken = () => {
    return localStorage.getItem("token")
}

export const getNameInitials = (first_name: string, last_name: string) => {
    const firstInitial = first_name[0] || ""
    const lastInitial = last_name[0] || ""
    return firstInitial + lastInitial
}

export const monthYearFormat = (date: string) => {
    const d = new Date(date)
    const month = d.toLocaleString("default", { month: "short" })
    const year = d.getFullYear()
    return `${month} ${year}`
}

export const getASINCount = (asinLength: number) => {
    if (asinLength <= 2) {
        return "T-FREE"
    } else if (asinLength <= 5) {
        return "T-5"
    } else if (asinLength <= 10) {
        return "T-10"
    } else if (asinLength <= 20) {
        return "T-20"
    } else if (asinLength <= 50) {
        return "T-50"
    } else if (asinLength <= 100) {
        return "T-100"
    } else {
        return "T-100"
    }
}

export const getTooltipMessage = (issue: string) => {
    switch (issue) {
        case "LISTING_CHANGE":
            return "Listing Change"
        case "DATA_UNAVAILABLE":
            return "ASIN data"
        case "DIFFERENT_ASIN_RETURNED":
            return "Different ASIN"
        case "CHILD_ASIN_RETURNED":
            return "Child ASIN"
        case "DIFFERENT_LARGE_CATEGORY":
            return "BSR L Cat"
        default:
            return "Unknown issue."
    }
}

// export const renderNotificationIcon = (productNotifications: any) => {
//     if (!productNotifications || productNotifications.length === 0) {
//         return "gray"
//     }

//     const allReadNull = productNotifications.every((notification: any) => notification.read_at === null)
//     const allReadNotNull = productNotifications.every((notification: any) => notification.read_at !== null)

//     if (allReadNull) {
//         return "red"
//     } else if (allReadNotNull) {
//         return "gray"
//     } else {
//         return "half"
//     }
// }

export const renderNotificationIcon = (productNotifications: any) => {
    if (!productNotifications || Object.keys(productNotifications).length === 0) {
        return "gray"
    }

    const allNotifications = Object.values(productNotifications).flat()

    if (allNotifications.length === 0) {
        return "gray"
    }

    const allReadNull = allNotifications.every((notification: any) => notification.read_at === null)
    const allReadNotNull = allNotifications.every((notification: any) => notification.read_at !== null)

    if (allReadNull) {
        return "red"
    } else if (allReadNotNull) {
        return "gray"
    } else {
        return "half"
    }
}

export const getNotificationMessage = (prevTrackpack: any, updatedTrackpack: any): string => {
    const getPrevStatuses = (trackpack: any) =>
        trackpack?.trackpackChildrenList?.map((child: any) => child.Status).flat()

    const getUpdatedStatuses = (data: any): string[] => {
        return data.trackings.map((tracking: any) => tracking.status)
    }

    const hasAllArchived = (statuses: any[]) =>
        statuses?.every((status: any) => status === "ARCHIVED_DURING_PACK_ARCHIVAL" || status === "ARCHIVED")

    const hasMixedStatuses = (statuses: any[]) =>
        statuses?.some((status: any) => status === "ARCHIVED_DURING_PACK_ARCHIVAL" || status === "ARCHIVED") &&
        statuses?.some((status: any) => status === "ACTIVE" || status === "AWAITING_INITIAL_DATA")

    const prevStatuses = getPrevStatuses(prevTrackpack)
    const updatedStatuses = getUpdatedStatuses(updatedTrackpack)

    const prevAllArchived = hasAllArchived(prevStatuses)
    const prevMixedStatuses = hasMixedStatuses(prevStatuses)

    const updatedMixedStatuses = hasMixedStatuses(updatedStatuses)

    if (prevAllArchived && updatedMixedStatuses) {
        return "trackpackMessage"
    }

    if (prevMixedStatuses && updatedMixedStatuses) {
        return "ASINMessage"
    }

    return "No applicable message"
}

export const handleAmazonProductRedirect = (amazonmarketplace: string, id: string) => {
    const amazonTld = amazonmarketplace ?? "com"
    const url = ASIN_AMAZON_LINK_BASE.replace("com", amazonTld) + id
    window.open(url, "_blank")
}

export const extractVideoId = (url: string): string => {
    const match = url?.match(/\/([a-z0-9-]{36})\//i)
    return match ? match[1] : ""
}
